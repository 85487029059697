<template>
  <SectionContainer
    title="Lokasi"
    class="location-section-container"
  >
    <div class="location-list-container">
      <NuxtLink
        v-for="location in locations"
        :key="location.text"
        :to="location.path"
        class="location-link"
      >
        <img
          :src="location.img"
          :alt="location.alt"
        />
        <Text
          type="p1"
          weight="bold"
        >
          {{ location.text }}
        </Text>
      </NuxtLink>
    </div>
    <div class="promotion">
      <div class="promotion-container">
        <div class="promotion-text">
          <Text
            type="h3"
            weight="bold"
          >
            Jual Properti Anda bersama kami dan dapatkan banyak benefit di
            dalamnya.
          </Text>
          <Text
            type="p1"
            weight="medium"
          >
            Solusi properti komersial Anda.
          </Text>
          <Button
            class="ads-btn"
            @click="onClickAddAds"
          >
            <PlusIcon
              width="14"
              height="14"
              fill="#FFF"
            />
            Pasang Iklan
          </Button>
        </div>
      </div>
    </div>
  </SectionContainer>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia'

  import SectionContainer from '~/components/common/SectionContainer.vue'

  import PlusIcon from '~/assets/icons/plus.svg'
  import JakartaImage from '~/assets/images/loc-jakarta.png'
  import BekasiImage from '~/assets/images/loc-bekasi.png'
  import TangerangImage from '~/assets/images/loc-tangerang.png'
  import CikarangImage from '~/assets/images/loc-cikarang.png'
  import KarawangImage from '~/assets/images/loc-karawang.png'

  import { useAuthStore, useCommonStore } from '~/store'

  interface ILocation {
    img: string
    alt: string
    text: string
    path: string
  }

  const locations: ILocation[] = [
    {
      img: JakartaImage,
      alt: 'location jakarta',
      text: 'Jakarta',
      path: '/search?keyword=jakarta',
    },
    {
      img: BekasiImage,
      alt: 'location bekasi',
      text: 'Bekasi',
      path: '/search?keyword=bekasi',
    },
    {
      img: TangerangImage,
      alt: 'location tangerang',
      text: 'Tangerang',
      path: '/search?keyword=tangerang',
    },
    {
      img: CikarangImage,
      alt: 'location cikarang',
      text: 'Cikarang',
      path: '/search?keyword=cikarang',
    },
    {
      img: KarawangImage,
      alt: 'location karawang',
      text: 'Karawang',
      path: '/search?keyword=karawang',
    },
  ]

  const { push } = useRouter()

  const authStore = useAuthStore()
  const { toggleLoginModal } = useCommonStore()

  const { state: authState } = storeToRefs(authStore)

  const isAuthenticated = computed(() => !!authState.value.profile.id)

  const onClickAddAds = () => {
    if (!isAuthenticated.value) {
      toggleLoginModal()
    } else {
      push('/me/add-ads')
    }
  }
</script>

<style scoped lang="scss">
  .location-section-container {
    background: $base300;
  }

  .location-list-container {
    display: flex;
    gap: 16px;
    justify-content: center;

    .location-link {
      position: relative;

      p {
        position: absolute;
        color: $base100;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .promotion {
    padding-top: 80px;
  }

  .promotion-container {
    display: flex;
    position: relative;
    color: $base100;
    background: url('~/assets/images/promotion-banner.png');
    height: 360px;
    margin: auto;
    background-position: center;
    background-size: cover;

    .promotion-text {
      padding: 80px;
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: 80%;
    }

    .ads-btn {
      width: fit-content;
      align-items: center;
      display: flex;
      gap: 8px;
    }
  }

  @media screen and (max-width: ($smallDesktop + 'px')) {
    .location-list-container {
      flex-wrap: wrap;
      padding: 0 24px;
    }

    .promotion {
      padding: 80px 24px 0;
    }

    .promotion-container {
      .promotion-text {
        padding: 60px;
      }
    }
  }

  @media screen and (max-width: ($tablet + 'px')) {
    .promotion-container {
      height: 560px;

      .promotion-text {
        padding: 60px 30px;
        width: 100%;
      }

      .ads-btn {
        width: 100%;
        justify-content: center;
      }
    }
  }
</style>
