<template>
  <SectionContainer
    title="Properti Terbaru"
    see-all-path="/search"
  >
    <Carousel
      :current-page="queryData.page"
      :total-page="properties.total_pages"
      :chevron-top-position="160"
      @prev="queryData.page = queryData.page - 1"
      @next="queryData.page = queryData.page + 1"
    >
      <PropertyCard
        v-for="property in properties.rows"
        :key="property.id"
        :data="property"
        is-hide-exclusive
      />
    </Carousel>
  </SectionContainer>
</template>

<script setup lang="ts">
  import SectionContainer from '~/components/common/SectionContainer.vue'
  import PropertyCard from '~/components/common/property/PropertyCard.vue'

  import { GET_PROPERTIES } from '~/constants'
  import { IListItem, IProperty } from '~/interfaces'

  const queryData = ref({ limit: 4, page: 1 })

  const { data: properties } = await api<IListItem<IProperty>>({
    path: GET_PROPERTIES,
    options: {
      query: queryData,
      watch: [queryData],
    },
  })
</script>
