<template>
  <SectionContainer
    title="Properti Populer"
    see-all-path="/search"
  >
    <div
      v-if="isUsingBigProperty && properties.rows"
      class="popular-properties-list"
    >
      <BigPropertyCard :data="properties.rows?.[0]" />

      <div class="other-popular-properties">
        <BigPropertyCard
          v-if="properties.rows?.[1]"
          :data="properties.rows?.[1]"
        />
        <BigPropertyCard
          v-if="properties.rows?.[2]"
          :data="properties.rows?.[2]"
        />
      </div>
    </div>

    <Carousel
      v-else
      :current-page="queryData.page"
      :total-page="properties.total_pages"
      :chevron-top-position="160"
      @prev="queryData.page = queryData.page - 1"
      @next="queryData.page = queryData.page + 1"
    >
      <PropertyCard
        v-for="property in properties.rows"
        :key="property.id"
        :data="property"
        is-hide-exclusive
      />
    </Carousel>
  </SectionContainer>
</template>

<script setup lang="ts">
  import SectionContainer from '~/components/common/SectionContainer.vue'
  import BigPropertyCard from '~/components/common/property/BigPropertyCard.vue'
  import PropertyCard from '~/components/common/property/PropertyCard.vue'

  import { GET_PROPERTIES } from '~/constants'
  import { IListItem, IProperty } from '~/interfaces'

  const queryData = ref({ limit: 4, page: 1 })
  const isUsingBigProperty = ref(true)

  const { data: properties } = await api<IListItem<IProperty>>({
    path: GET_PROPERTIES,
    options: {
      query: queryData,
      watch: [queryData],
    },
  })

  onMounted(() => {
    isUsingBigProperty.value = window.screen.width > 1024
  })
</script>

<style lang="scss">
  .popular-properties-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 860px;
    gap: 60px;
    justify-content: space-between;

    .other-popular-properties {
      display: grid;
      gap: 24px;
      grid-template-rows: 1fr 1fr;
    }
  }
</style>
