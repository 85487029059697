<template>
  <div
    class="select-input-container"
    :class="showOptions && 'select-container--expanded'"
  >
    <label for="categorySelect">
      <Text
        type="p3"
        weight="bold"
      >
        Kategori
      </Text>
    </label>
    <div
      class="select-container"
      @click="setShowOptions"
    >
      <Text
        type="p1"
        weight="bold"
        class="select-main-option"
      >
        {{ selectedOption.name }}
      </Text>

      <ChevronRightIcon
        width="16"
        height="16"
        :transform="showOptions ? 'rotate(-90)' : 'rotate(90)'"
      />
    </div>
    <div
      v-if="showOptions"
      class="select-options"
    >
      <Text
        v-for="category in propertyState.categories"
        :key="category.id"
        type="p2"
        weight="bold"
        class="option"
        :class="selectedOption.id === category.id && 'option--selected'"
        @click="() => handleClickSelect(category)"
      >
        {{ category.name }}
      </Text>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia'

  import ChevronRightIcon from '~/assets/icons/chevron-right.svg'

  import { IPropertyCategory } from '~/interfaces'
  import { usePropertyStore } from '~/store'

  interface IPropertyInputSelectProps {
    selectedOption: IPropertyCategory
  }

  interface IPropertyInputSelectEmits {
    (e: 'selectOption', value: IPropertyCategory): void
  }

  defineProps<IPropertyInputSelectProps>()
  const emits = defineEmits<IPropertyInputSelectEmits>()

  const propertyStore = usePropertyStore()
  const { state: propertyState } = storeToRefs(propertyStore)

  const showOptions = ref(false)

  const setShowOptions = () => {
    showOptions.value = !showOptions.value
  }

  const handleClickSelect = (category: IPropertyCategory) => {
    emits('selectOption', category)
    setShowOptions()
  }
</script>

<style scoped lang="scss">
  .select-input-container {
    padding: 16px 24px;
    background: $base100;
    position: relative;

    label {
      color: $base800;
      margin-bottom: 2px;
    }

    .select-container {
      display: flex;
      width: 130px;
      justify-content: space-between;
      cursor: pointer;
    }

    .select-container--expanded {
      border-bottom: 1px solid $base400;
    }

    .select-main-option {
      color: $base900;
    }

    .select-options {
      position: absolute;
      left: 0;
      right: 0;
      margin-top: 16px;
      background: $base100;
      max-height: 200px;
      overflow-y: scroll;
    }

    .option {
      padding: 10px 24px;
      color: $base900;
      cursor: pointer;
      border-bottom: 1px solid $base400;

      &:hover {
        opacity: 0.75;
      }

      &:last-child {
        border-bottom: none;
      }
    }

    .option--selected {
      color: $orange500;
      background: $orange100;
      border-bottom: none;
    }
  }
</style>
