<template>
  <SectionContainer
    v-if="properties.total_rows"
    title="Properti Eksklusif"
    see-all-path="/search"
    class="exclusive-properties-container"
  >
    <Carousel
      :current-page="queryData.page"
      :total-page="properties.total_pages"
      :chevron-top-position="160"
      @prev="queryData.page = queryData.page - 1"
      @next="queryData.page = queryData.page + 1"
    >
      <PropertyCard
        v-for="property in properties.rows"
        :key="property.id"
        :data="property"
      />
    </Carousel>
  </SectionContainer>
</template>

<script setup lang="ts">
  import SectionContainer from '~/components/common/SectionContainer.vue'
  import PropertyCard from '~/components/common/property/PropertyCard.vue'

  import { GET_PROPERTIES } from '~/constants'
  import { IListItem, IProperty } from '~/interfaces'

  const queryData = ref({ limit: 4, page: 1, highlight: true })

  const { data: properties } = await api<IListItem<IProperty>>({
    path: GET_PROPERTIES,
    options: {
      query: queryData,
      watch: [queryData],
    },
  })
</script>

<style scoped lang="scss">
  .exclusive-properties-container {
    background: $base300;
  }
</style>
