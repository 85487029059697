<template>
  <div
    class="search-history-container"
    @click="handleClickSearchHistory"
  >
    <SearchIcon
      width="14"
      height="14"
    />
    <Text
      type="p4"
      weight="bold"
    >
      {{ data.keyword ? data.keywordText : data.text }}
    </Text>
  </div>
</template>

<script setup lang="ts">
  import SearchIcon from '~/assets/icons/search.svg'
  import { ISetSearchHistoriesProps } from '~/utils'

  interface ISearchHistoryProps {
    data: ISetSearchHistoriesProps
  }

  const props = defineProps<ISearchHistoryProps>()

  const { push } = useRouter()

  const handleClickSearchHistory = () => {
    const query: Record<string, string> = {
      type: props.data.type,
      category: props.data.category.key,
    }

    if (props.data.keyword) {
      query.keyword = props.data.keyword
    }

    if (props.data.area.value) {
      query[props.data.area.queryKey] = props.data.area.value
      query.keyword = props.data.area.label
    }

    push({
      path: '/search',
      query,
    })
  }
</script>

<style scoped lang="scss">
  .search-history-container {
    cursor: pointer;
    background: rgba(255, 255, 255, 0.2);
    color: $base100;
    padding: 8px;
    width: fit-content;
    display: flex;
    align-items: center;
    white-space: nowrap;

    p {
      margin-left: 4px;
    }
  }
</style>
