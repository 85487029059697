<template>
  <Modal
    v-if="isShowModal"
    @close-modal="setCloseModal"
  >
    <Text
      type="p1"
      weight="bold"
      class="modal-title"
    >
      Tipe Properti
    </Text>

    <div class="property-types-menu">
      <Button
        v-for="propertyCategory in propertyState.categories"
        :key="propertyCategory.id"
        text-only
        @click.prevent="
          () =>
            onClickPropertyTypeMenu(`/search?category=${propertyCategory.slug}`)
        "
      >
        <component
          :is="PropertyTypesIcon[propertyCategory.slug]"
          class="btn-img"
        />
        <Text type="p3">
          {{ propertyCategory.name }}
        </Text>
      </Button>
    </div>
  </Modal>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia'

  import { PropertyTypesIcon } from '~/constants'
  import { usePropertyStore } from '~/store'

  interface IPropertyTypeModalProps {
    isShowModal: boolean
    setCloseModal: () => void
  }

  const props = defineProps<IPropertyTypeModalProps>()

  const { push } = useRouter()

  const propertyStore = usePropertyStore()
  const { state: propertyState } = storeToRefs(propertyStore)

  const onClickPropertyTypeMenu = (path?: string) => {
    if (path) {
      push(path)
      return
    }

    props.setCloseModal()
  }
</script>

<style scoped lang="scss">
  .modal-title {
    text-align: center;
    margin-bottom: 24px;
  }

  .property-types-menu {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    row-gap: 24px;

    button {
      color: $base900;
      padding: 24px;
    }

    .btn-img {
      width: 80px;
      height: 80px;
    }
  }

  @media screen and (max-width: ($tablet + 'px')) {
    .property-types-menu {
      grid-template-columns: 1fr 1fr 1fr;
      row-gap: 16px;

      button {
        padding: 8px 14px;
      }

      .btn-img {
        width: 50px;
        height: 50px;
      }

      button:last-child {
        grid-column-end: 3;
      }
    }
  }

  @media screen and (max-width: ($mobileLarge + 'px')) {
    .property-types-menu {
      grid-template-columns: 96px 96px 96px;
      justify-content: center;
    }
  }

  @media screen and (max-width: ($mobile + 'px')) {
    .property-types-menu {
      grid-template-columns: 80px 80px 80px;
      justify-content: center;

      button {
        padding: 4px;
      }

      .btn-img {
        width: 32px;
        height: 32px;
      }
    }
  }
</style>
