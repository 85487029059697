<template>
  <section class="property-types-section-container">
    <DefaultContainer>
      <div>
        <Text
          type="h4"
          weight="bold"
          class="section-title"
        >
          Tipe Properti
        </Text>

        <div class="property-types-menu">
          <Button
            v-for="propertyCategory in propertyState.categories.slice(0, 5)"
            :key="propertyCategory.id"
            text-only
            @click.prevent="
              () =>
                onClickPropertyTypeMenu(
                  `/search?category=${propertyCategory.slug}`,
                )
            "
          >
            <component
              :is="PropertyTypesIcon[propertyCategory.slug]"
              class="btn-img"
            />
            <Text type="p2">
              {{ propertyCategory.name }}
            </Text>
          </Button>

          <Button
            text-only
            @click.prevent="() => onClickPropertyTypeMenu()"
          >
            <OthersIcon class="btn-img" />
            <Text type="p2"> Lainnya </Text>
          </Button>
        </div>
      </div>
      <PromoList class="ads-promo" />
      <PropertyTypeModal
        :is-show-modal="isShowModal"
        :set-close-modal="setCloseModal"
      />
    </DefaultContainer>
  </section>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia'

  import PromoList from '~/components/common/promo/PromoList.vue'
  import PropertyTypeModal from '~/components/common/property/PropertyTypeModal.vue'

  import OthersIcon from '~/assets/icons/PropertyType/others.svg'

  import { PropertyTypesIcon } from '~/constants'
  import { usePropertyStore } from '~/store'

  const isShowModal = ref(false)
  const { push } = useRouter()

  const propertyStore = usePropertyStore()
  const { state: propertyState } = storeToRefs(propertyStore)

  const onClickPropertyTypeMenu = (path?: string) => {
    if (path) {
      push(path)
      return
    }

    isShowModal.value = true
  }

  const setCloseModal = () => {
    isShowModal.value = false
  }
</script>

<style scoped lang="scss">
  .property-types-section-container {
    padding: 60px 110px 80px;

    .section-title {
      margin-bottom: 24px;
      text-align: center;
    }

    .property-types-menu {
      display: flex;
      justify-content: center;
      text-align: center;
    }

    button {
      padding: 24px 35px;
      color: $base900;
    }

    .btn-img {
      width: 80px;
      height: 80px;
    }

    .ads-promo {
      margin-top: 60px;
    }
  }

  @media screen and (max-width: ($smallDesktop + 'px')) {
    .property-types-section-container {
      padding: 40px 24px;

      .property-types-menu {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
  }

  @media screen and (max-width: ($tablet + 'px')) {
    .property-types-section-container {
      button {
        padding: 8px 22px;
      }

      .btn-img {
        width: 50px;
        height: 50px;
      }
    }
  }

  @media screen and (max-width: ($mobile + 'px')) {
    .property-types-section-container {
      button {
        padding: 4px;
      }

      .btn-img {
        width: 32px;
        height: 32px;
      }
    }
  }
</style>
