<template>
  <SectionContainer
    title="Artikel"
    see-all-path="/article"
  >
    <Carousel
      :current-page="queryData.page"
      :total-page="articles.total_pages"
      :chevron-top-position="160"
      @prev="queryData.page = queryData.page - 1"
      @next="queryData.page = queryData.page + 1"
    >
      <ArticleCard
        v-for="article in articles.rows"
        :key="article.id"
        :data="article"
      />
    </Carousel>
  </SectionContainer>
</template>

<script setup lang="ts">
  import SectionContainer from '~/components/common/SectionContainer.vue'
  import ArticleCard from '~/components/common/article/ArticleCard.vue'

  import { GET_ARTICLES } from '~/constants'
  import { IArticle, IListItem } from '~/interfaces'

  const queryData = ref({ limit: 4, page: 1 })

  const { data: articles } = await api<IListItem<IArticle>>({
    path: GET_ARTICLES,
    options: {
      query: queryData,
      watch: [queryData],
    },
  })
</script>

<style scoped lang="scss"></style>
