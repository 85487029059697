<template>
  <div>
    <div class="main-container">
      <div>
        <button
          class="type-btn"
          :class="activeBtn === EPropertySellType.Sale && 'type-btn--active'"
          @click="() => setActiveBtn(EPropertySellType.Sale)"
        >
          <span> Beli </span>
        </button>
        <button
          class="type-btn"
          :class="activeBtn === EPropertySellType.Rent && 'type-btn--active'"
          @click="() => setActiveBtn(EPropertySellType.Rent)"
        >
          <span> Sewa </span>
        </button>
        <PropertyInputSelect
          :selected-option="selectedOption"
          class="property-type-select"
          @select-option="handleSelectOption"
        />
      </div>
      <PropertyAutocomplete
        is-button-arrow
        is-show-label
        :is-show-button="isDesktopScreen"
        :search="search"
        :selected-suggestion="selectedSuggestion"
        class="input-autocomplete"
        @set-search="handleSetSearch"
        @choose-suggestion="handleClickSuggestion"
        @click-search="handleClickSearch"
      />
    </div>
    <div class="history-list-container mt-8">
      <SearchHistory
        v-for="(history, idx) in searchHistories"
        :key="idx"
        :data="history"
      />
    </div>

    <div
      v-if="!isDesktopScreen"
      class="btn-search-container mt-8"
    >
      <Button
        class="btn-search"
        @click="handleClickSearch"
      >
        Cari
        <ArrowIcon
          width="24"
          height="24"
          fill="#FFF"
          transform="rotate(180)"
        />
      </Button>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia'

  import PropertyAutocomplete from './PropertyAutocomplete.vue'
  import PropertyInputSelect from './PropertyInputSelect.vue'
  import SearchHistory from './SearchHistory.vue'

  import ArrowIcon from '~/assets/icons/arrow.svg'

  import { usePropertySearchAutocomplete } from '~/hooks'
  import { usePropertyStore } from '~/store'
  import { getSearchHistories, ISetSearchHistoriesProps } from '~/utils'
  import { EPropertySellType, IPropertyCategory } from '~/interfaces'

  const propertyStore = usePropertyStore()
  const { state: propertyState } = storeToRefs(propertyStore)

  const activeBtn = ref<EPropertySellType>(EPropertySellType.Sale)
  const selectedOption = ref<IPropertyCategory>(
    propertyState.value.categories[0],
  )
  const isDesktopScreen = ref(true)
  const searchHistories = ref<ISetSearchHistoriesProps[]>([])

  const { push } = useRouter()
  const { search, selectedSuggestion, handleSetSearch, handleClickSuggestion } =
    usePropertySearchAutocomplete()

  onMounted(() => {
    isDesktopScreen.value = window.screen.width > 768
  })

  const setActiveBtn = (value: EPropertySellType) => {
    activeBtn.value = value
  }

  const handleSelectOption = (value: IPropertyCategory) => {
    selectedOption.value = value
  }

  const handleClickSearch = () => {
    const textType =
      activeBtn.value === EPropertySellType.Sale ? 'Jual' : 'Sewa'
    const textCategory = selectedOption.value.name
    const textArea = selectedSuggestion.value?.name
      ? `di ${selectedSuggestion.value?.name}`
      : ''
    const text = `${textType} ${textCategory} ${textArea}`
    const keywordText = `${textType} ${textCategory} dengan nama ${search.value}`

    setSearchHistories({
      keyword: search.value,
      keywordText,
      text,
      type: activeBtn.value,
      category: {
        key: selectedOption.value.slug,
        label: selectedOption.value.name,
      },
      area: {
        queryKey: selectedSuggestion.value?.queryKey || 'province_id',
        value: selectedSuggestion.value?.id || '',
        label: selectedSuggestion.value?.name || '',
      },
    })

    const query: Record<string, string> = {
      type: activeBtn.value,
      category: selectedOption.value.slug,
    }

    if (search.value) {
      query.keyword = search.value
    }

    if (selectedSuggestion.value) {
      query[selectedSuggestion.value.queryKey] = selectedSuggestion.value.id
      query.keyword = selectedSuggestion.value.name
    }

    push({
      path: '/search',
      query,
    })
  }

  onMounted(() => {
    searchHistories.value = getSearchHistories()
  })
</script>

<style scoped lang="scss">
  .main-container {
    display: flex;

    .type-btn {
      width: 50%;
      padding: 12px 24px 16px;
      outline: none;
      border: none;
      cursor: pointer;
      background: $base400;
      color: $base900;
      font-weight: bold;
      font-size: 14px;
      border-bottom: 1px solid $base400;
    }

    .type-btn--active {
      background: $base100;

      span {
        padding-bottom: 4px;
        border-bottom: 2px solid $orange500;
      }
    }

    .property-type-select {
      border-right: 1px solid $base400;
    }

    .input-autocomplete {
      align-self: flex-end;
      height: 74px;
    }
  }

  .history-list-container {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }

  @media screen and (max-width: ($smallDesktop + 'px')) {
    .main-container {
      padding: 0 32px;
    }

    .history-list-container {
      padding: 0 32px;
      flex-wrap: nowrap;
      overflow-x: scroll;
    }
  }

  @media screen and (max-width: ($tablet + 'px')) {
    .main-container {
      .input-autocomplete {
        height: 70px;
      }
    }

    .btn-search-container {
      padding: 0 32px;
    }

    .btn-search {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 100%;

      svg {
        position: absolute;
        right: 12px;
      }
    }
  }
</style>
